<template>
  <v-col class="regads mt-2">
    <v-col class="shades white pa-6">
      <v-row  class="px-4">
        <v-col cols="12" md="12">
          <h3>매장에 대한 정보를 입력해주세요.</h3>
        </v-col>
        <v-col md="6">
          <div class="label mt-6 mb-2">매장이 위치한 지역을 선택해주세요.</div>
          <v-row>
            <v-col md="6">
              <v-select v-model="form.local.region"
                        :items="Object.entries((option || {}).local || {}).map(([k, v]) => ({text: k, value: k})) || []"
                        item-text="text"
                        item-value="value"
                        label="지역"
                        height="40px"
                        :hide-details="true"
                        outlined solo flat
                        @change="checkDone"
                        class="mr-2"></v-select>
            </v-col>
            <v-col md="6">
              <v-select v-model="form.local.code"
                        :items="Object.entries(((option || {}).local || {})[form.local.region] || {}).map(([k, v]) => ({text: v, value: k})) || []"
                        item-text="text"
                        item-value="value"
                        label="상권"
                        height="40px"
                        :hide-details="true"
                        outlined solo flat
                        @change="checkDone"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="shades white pa-6 mt-2">
      <v-row  class="px-4">
        <v-col cols="12" md="10">
          <div class="label mb-2">매장 이름을 입력해주세요.</div>
          <v-text-field v-model="form.serviceName" name="serviceName" type="text"
                        :rules="[errors.first('serviceName') || !errors.has('serviceName')]"
                        placeholder="업체명과 지점명을 함께 적어주세요."
                        @input="checkDone"
                        single-line outlined required></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12" >
          <div class="label mb-2">매장의 주소를 알려주세요.</div>
          <v-row >
            <v-col cols="8">
              <v-text-field v-model="form.address" name="address" type="text"
                            :rules="[errors.first('address') || !errors.has('address')]"
                            placeholder="주소 검색 버튼을 클릭해주세요."
                            @input="checkDone"
                            readonly hide-details
                            single-line outlined required></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-menu v-model="daumMap">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="rouge" class="ma-0 ml-2" outlined style="width: 98px; height: 40px">주소 검색</v-btn>
                </template>
                <vue-daum-postcode @complete="form.address = $event.address; daumMap = false" style="max-height: 500px; overflow: scroll;"></vue-daum-postcode>
              </v-menu>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="form.addressDetail" name="addressDetail" type="text"
                            :rules="[errors.first('addressDetail') || !errors.has('addressDetail')]"
                            placeholder="상세 주소를 입력해주세요."
                            @input="checkDone"
                            single-line outlined></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mt-1" cols="12" md="10" >
          <div class="label mb-2">매장의 연락처를 알려주세요.</div>
          <v-text-field v-model="form.contacts" name="contacts" type="text"
                        :rules="[errors.first('contacts') || !errors.has('contacts')]"
                        placeholder="예) 02-1234-1234"
                        @input="checkDone"
                        single-line outlined></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12" md="10" >
          <div class="label mb-2">매장의 영업시간을 알려주세요.</div>
          <v-text-field v-model="form.businessHours" name="businessHours" type="text"
                        :rules="[errors.first('businessHours') || !errors.has('businessHours')]"
                        hint="예) 월 - 토 10:00 - 22:00 (브레이크타임 15:00 - 17:00) / 일요일 휴무"
                        persistent-hint
                        @input="checkDone"
                        single-line outlined></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12" md="10" >
          <div class="label mb-2">인스타그램 공식 계정 아이디를 입력해주세요.</div>
          <v-text-field v-model="form.instaOfficial" name="instaOfficial" type="text"
                        :rules="[errors.first('instaOfficial') || !errors.has('instaOfficial')]"
                        hint="공식 계정이 있는 경우 인스타그램 아이디만 입력해주세요. (@제외)"
                        persistent-hint
                        @input="checkDone"
                        single-line outlined></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
  import {EventBus} from "@/components/shared/event-bus";

  export default {
    name: 'Page2',
    components: {
      VueDaumPostcode: () => import('@/components/shared/vue-daum-postcode')
    },
    props: ['camData', 'option'],
    data: function () {
      return {
        form: {
          local: {
            code: (this.camData.local || {}).code || '',
            region: (this.camData.local || {}).region || ''
          },
          serviceName: this.camData.serviceName || '',
          address: this.camData.address || '',
          addressDetail: this.camData.addressDetail || '',
          contacts: this.camData.contacts || '',
          businessHours: this.camData.businessHours || '',
          instaOfficial: this.camData.instaOfficial || '',
          step: 2
        },
        daumMap: false,
        checkUTM: false,
        checkNT: false,
        utm: {
          source: 'naver',
          medium: 'blog',
          campaign: this.camData.name
        },
        nt: {
          source: 'naver',
          medium: 'blog',
          detail: this.camData.name,
          keyword: this.camData.name
        }
      }
    },
    watch: {
      // 'camData.name': function (value) {
      //   this.form.name = value;
      // }
    },
    methods: {
      checkDone() {
        this.$validator.validateAll().then(success => {
          if (success) {
            this.form.step = 2;
            // this.$emit('updateEvent', this._.defaults(this.form, this.camData));
            this.$emit('updateEvent', this.form);
          }
        });
      }
    },
    created() {
      // this.checkDone();
      EventBus.$on("update:campaign", async () => {
        this._.merge(this.form, this._.pick(this.camData, ['serviceName', 'address', 'contacts', 'instaOfficial']));
        this._.keys((this.option || {}).local || {}).map(key => {
          if (this.form.address.indexOf(key) > -1) {
            this.form.local.region = key;
          }
        });
        if (this.form.address.indexOf("울산") > -1 || this.form.address.indexOf("부산") > -1 || this.form.address.indexOf("경남") > -1) {
          this.form.local.region = "부산/경남";
        }
        else if (this.form.address.indexOf("대구") > -1 || this.form.address.indexOf("경북") > -1) {
          this.form.local.region = "대구/경북";
        }
        else if (this.form.address.indexOf("대전") > -1 || this.form.address.indexOf("충남") > -1 || this.form.address.indexOf("충북") > -1) {
          this.form.local.region = "대전/충청";
        }
        else if (this.form.address.indexOf("광주") > -1 || this.form.address.indexOf("전남") > -1 || this.form.address.indexOf("전북") > -1) {
          this.form.local.region = "광주/전라";
        }
      });
    }
  }
</script>
<style scoped lang="scss">
  @import '../../../../assets/scss/custom';

  ::v-deep .v-list-item {
    font-size: 14px;
  }
</style>